import React from 'react'
import NextLink from 'next/link'

import config from 'site.config'

export default function Footer() {
  return (
    <footer className="py-4 text-gray-700 dark:text-gray-400 space-x-4 justify-center">
      <div>
        <p className="text-xs">
          Disclaimer: emi-calculators.com is purely for informational purposes and does not offer any specific
          financial, investment, accounting, tax,commercial or legal advice. Bank logo, name and other material used on
          emi-calculators.com are copyright of respective org. emi-calculators.com are not in any way related to any
          bank or financial org listed at emi-calculators.com. While publishing the data on our website, we have tried
          our best to keep entire information updated and correct from RBI website, banks website and Internet
          resources. However, emi-calculators.com does not guarantee the reliability, topicality,accuracy, correctness,
          completeness or quality of the information provided. All the information on emi-calculators.com should never
          be used without verification, on any kind of transaction therefore users are requested to confirm information
          with their respective bank before using the information. Liability claims regarding damage caused by the use
          of any information provided, including any kind of information which is incomplete or incorrect, will
          therefore be rejected. In case you find errors or mistakes, kindly report them to{' '}
          <a href="mailto:emicalcualtor@gmail.com">emicalcualtor@gmail.com</a>
          Also, please reach to the same email-id if you are a bank/lender and want your IFSC Codes listed on this
          website.
        </p>
        <br></br>
      </div>
      <div>
        <span>{config.meta.copyright}</span>
      </div>
      {/* <NextLink href="/banks">
        <a>Banks</a>
      </NextLink>
      <NextLink href="/loans">
        <a>Loans</a>
      </NextLink>
      <NextLink href="/loans">
        <a>Interest Rates</a>
      </NextLink>
      <NextLink href="/loans">
        <a>Loan Calculators</a>
      </NextLink> */}
    </footer>
  )
}
