import {default as menu} from './menu.json'
export const menuJson = menu

// {
//   "url": "/emi-calculator/",
//   "menuId": "bike-loan",
//   "menuName": "Bike Loan",
//   "submenuitems": [
//     {
//       "id": "sbi",
//       "submenuName": "SBI"
//     },
//     {
//       "id": "hdfc",
//       "submenuName": "HDFC"
//     },
//     {
//       "id": "icici",
//       "submenuName": "ICICI"
//     },
//     {
//       "id": "axis-bank",
//       "submenuName": "Axis"
//     },
//     {
//       "id": "kotak-bank",
//       "submenuName": "Kotak"
//     },
//     {
//       "id": "yes-bank",
//       "submenuName": "Yes Bannk"
//     },
//     {
//       "id": "indusind-bank",
//       "submenuName": "IndusInd"
//     }
//   ]
// },
// {
//   "url": "/emi-calculator/",
//   "menuId": "/india/loans/business-loan",
//   "menuName": "Business Loan",
//   "submenuitems": [
//     {
//       "id": "sbi",
//       "submenuName": "SBI"
//     },
//     {
//       "id": "hdfc",
//       "submenuName": "HDFC"
//     },
//     {
//       "id": "icici",
//       "submenuName": "ICICI"
//     },
//     {
//       "id": "axis-bank",
//       "submenuName": "Axis"
//     },
//     {
//       "id": "kotak-bank",
//       "submenuName": "Kotak"
//     },
//     {
//       "id": "yes-bank",
//       "submenuName": "Yes Bannk"
//     },
//     {
//       "id": "indusind-bank",
//       "submenuName": "IndusInd"
//     }
//   ]
// },
